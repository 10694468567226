@media screen and (max-width: 995px) {
  .side-filters {
    position: absolute;
    top: 15rem;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 1000;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
  }

  .product-list {
    margin-top: 30%;
    position: static;
  }

  .side-filters.active {
    left: 0;
  }

  .side-filters-overalay {
    display: none;
  }
}

@media screen and (max-width: 320px) {
  .product-list {
    margin-top: 80%;
  }
}

@media screen and (max-width: 425px) {
  .product-list {
    margin-top: 65%;
  }
}

.text-wrap {
  white-space: nowrap;
}

.sigCanvas {
  border: 1px solid #9e9e9e;
}

.button-box {
  background-color: #dc3558;
  border: none;
  padding: 5px 10px;
  margin: 2px 5px;
  color: #fff;
}

.button-box:hover {
  background-color: transparent;
  border: 1px solid #dc3558;
  color: #dc3558;
}

.side-filters {
  /* width: 400px; */
  z-index: 1000;
  overflow-y: scroll;
  transition: all 0.3s ease-in-out;
}

.side-filters.active {
  left: 0;
}

.side-filters-overalay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0.5;
}

.sidebar-style-new {
  padding: 30px 10px;
}

.sidebar-style-new .sidebar-title {
  font-size: 22px;
  font-weight: 600;
}

.filter-accordion {
  cursor: pointer;
  padding: 15px 5px 15px 10px;
  width: 100%;
  text-align: left;
  outline: none;
  transition: 0.4s;
  font-size: 1.2rem;
  /*   background-color: #ebf9fe;
    margin-bottom: 2px;*/
}

.filter-accordion:after {
  content: "\02795";
  /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: #777;
  float: right;
  margin-left: 5px;
}

.panel-active:after {
  content: "\2796";
  /* Unicode character for "minus" sign (-) */
}

.panel-active,
.filter-accordion:hover {
  color: #dc3558;
}

.panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  border-bottom: 1px solid #bbbbbb;
}

.panel p {
  padding: 20px;
  font-size: 1.6rem;
}

.panel ul {
  margin: 40px 0;
}

.panel ul > li {
  font-size: 1.1rem;
  margin-left: 10px;
}

.panel ul > li input {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.panel ul > li label {
  cursor: pointer;
}

.sidebar-widget {
  /* border-top: 1px solid #bbbbbb; */
}

.active-filters-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px 15px;
  gap: 0.8rem;
}

.active-filter-item {
  border: 1px solid #dc3558;
  padding: 3px 4px 5px 5px;
  line-height: 1.4;
}

.active-filter-item button {
  transform: translateY(2px);
  margin-left: 5px;
}

.filter-btn {
  background-color: #dc3558;
  border: none;
  padding: 5px 10px;
  margin: 2px 5px;
  color: #fff;
  height: 35px;
  width: 120px;
}

.check-disabled {
  pointer-events: none;
  opacity: 0.4;
}

.no-products {
  text-align: center;
  font-size: 1.6rem;
  padding: 20px;
  color: #dc3558;
}

.load-more-btn {
  background-color: #dc3558;
  border: none;
  padding: 5px 10px;
  margin: 2px 5px;
  color: #fff;
  height: 35px;
  width: 120px;
  margin: 0 auto;
  display: block;
}

.overflow-lable {
  height: 150px;
  overflow-y: scroll;
}

.cust-card {
  height: 431px !important;
  display: grid !important;
  align-items: center;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.check-group-resi {
  display: flex;
  align-items: center;
}

.check-group-resi input {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.check-group-resi label {
  cursor: pointer;
  font-size: 15px;
  line-height: normal;
}

.thankyou-wrapper {
  width: 100%;
  height: auto;
  margin: auto;
  background: #ffffff;
  padding: 10px 0px 50px;
}

.thankyou-wrapper h1 {
  font: 100px Arial, Helvetica, sans-serif;
  text-align: center;
  color: #333333;
  padding: 0px 10px 10px;
}

.thankyou-wrapper p {
  font: 26px Arial, Helvetica, sans-serif;
  text-align: center;
  color: #333333;
  padding: 5px 10px 10px;
}

.thankyou-wrapper a {
  font: 26px Arial, Helvetica, sans-serif;
  text-align: center;
  color: #ffffff;
  display: block;
  text-decoration: none;
  width: 250px;
  background: #dc3558;
  margin: 10px auto 0px;
  padding: 15px 20px 15px;
}

.thankyou-wrapper a:hover {
  font: 26px Arial, Helvetica, sans-serif;
  text-align: center;
  color: #ffffff;
  display: block;
  text-decoration: none;
  width: 250px;
  background: #ff1246;
  margin: 10px auto 0px;
  padding: 15px 20px 15px;
}

.searched-products {
  background: white;
  position: absolute;
  z-index: 9999;
  width: 25%;
  top: 82px;
  padding: 15px;
  border-radius: 5px;
  max-height: 300px;
  overflow-y: scroll;
}

.searched-products .searched-product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

/* customize scroll bar */
.searched-products::-webkit-scrollbar {
  width: 5px;
}

.searched-products::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.searched-products::-webkit-scrollbar-thumb {
  background: #888;
}

.searched-products::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.filter-btn {
  text-align: center;
  color: white;
}

.filter-btn:hover {
  background-color: #fff;
  border: 1px solid #dc3558;
  color: #dc3558;
}

.product-loading {
  display: flex;
  height: 50vh;
  align-items: center;
}

.product-loading div {
  position: absolute;
  top: 50%;
  left: 50%;
}

.active-filter-item span {
  text-transform: capitalize;
}

.state-select {
  font-size: 14px;
  width: 100%;
  height: 45px;
  padding-left: 10px;
  color: #333;
  border: 2px solid #eceff8;
  background: #eceff8;
  box-shadow: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.animate {
  height: 100%;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
}

.animate > li {
  position: absolute;
  height: 50px;
  width: 50px;
  top: 100%;
  left: 10px;
  background-color: rgba(255, 0, 0, 0.4);
  z-index: 9999;
  overflow: hidden;
  animation: move 10s linear infinite;
}

.animate > li:nth-last-of-type(2) {
  left: 70px;
  animation-delay: 3.5s;
  height: 15px;
  width: 15px;
}

.animate > li:nth-last-of-type(3) {
  left: 140px;
  animation-delay: 3s;
}

.animate > li:nth-last-of-type(4) {
  left: 210px;
  animation-delay: 5.5s;
}

.animate > li:nth-last-of-type(5) {
  left: 280px;
  animation-delay: 1.8s;
  height: 65px;
  width: 65px;
}

.animate > li:nth-last-of-type(6) {
  left: 140px;
  animation-delay: 6.8s;
  height: 25px;
  width: 25px;
}

.animate > li:nth-last-of-type(7) {
  left: 280px;
  animation-delay: 5s;
  height: 35px;
  width: 35px;
}

@keyframes move {
  to {
    top: -50px;
    transform: rotate(360deg);
  }
}

/* .hero-swiper {
  height: 100vh;
  width: 100%;
  position: relative;
} */

.same-style select {
  padding: 10px 0;
  width: 100%;
  color: #333;
  border-radius: 0;
  background: #fff;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-size: 15px;
}

.same-style select:focus {
  border: 1px solid #dc3558;
}

.box {
  margin: 10px;
  padding-left: 40px;
  text-align: center;
  width: 300px;
}

.button {
  font-size: 1em;
  padding: 10px;
  color: #fff;
  border: 2px solid #dc3558;
  border-radius: 20px/50px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.button:hover {
  background: #ff0e42;
  color: #fff;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  z-index: 9999;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}

.popup .close {
  position: absolute;
  top: 7px;
  right: 10px;
  transition: all 200ms;
  font-size: 18px;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #dc3558;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px) {
  .box {
    width: 70%;
  }
  .popup {
    width: 70%;
  }
}

.same-style a button {
  white-space: nowrap;
}

.cart-item-container {
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.cart-item-details {
  margin-left: 10px;
}

.missing-field input {
  border: 1px solid red;
}

.missing-field small {
  color: red;
}

.check-group {
  height: 120px;
  overflow-y: scroll;
}
/*  */
.card {
  margin-bottom: 20px;
}

.card-header {
  border-bottom: 1px solid #ddd;
}

.order-details {
  padding: 15px;
  background-color: #f9f9f9;
  border-top: 1px solid #ddd;
}

.order-details h5 {
  margin-top: 10px;
  font-weight: bold;
}

.order-details p {
  margin: 5px 0;
  padding-left: 15px;
  text-indent: -15px;
}

.order-details .store-total {
  font-weight: bold;
  margin-top: 10px;
}

.order-details .total {
  font-weight: bold;
  margin-top: 20px;
  text-align: right;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}
